<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.8" x2="0.309" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_auto" transform="translate(-598 -2337)">
    <rect :fill="background" data-name="Rectangle 179" height="183" id="Rectangle_179" transform="translate(598 2337)" width="183"/>
    <g data-name="MDI / car-hatchback" id="MDI_car-hatchback" transform="translate(622.703 2362.12)">
     <g fill="url(#linear-gradient)" id="Boundary" opacity="0" stroke="rgba(0,0,0,0)" stroke-width="1">
      <rect :fill="background" height="133.594" stroke="none" width="133.594"/>
      <rect :fill="background" height="132.594" width="132.594" x="0.5" y="0.5"/>
     </g>
     <path d="M84.5,6H28.832L1,39.4V56.1H12.133a16.7,16.7,0,1,0,33.4,0h33.4a16.7,16.7,0,0,0,33.4,0h11.133V39.4a11.094,11.094,0,0,0-11.133-11.133H101.2L84.5,6M31.615,14.35H53.881V28.266h-33.4L31.615,14.35m30.615,0H81.713l10.91,13.916H62.23V14.35m-33.4,33.4a8.35,8.35,0,1,1-8.35,8.35,8.35,8.35,0,0,1,8.35-8.35m66.8,0a8.35,8.35,0,1,1-8.35,8.35A8.35,8.35,0,0,1,95.629,47.748Z" data-name="Path / car-hatchback" fill="url(#linear-gradient)" id="Path_car-hatchback" transform="translate(4.566 27.398)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>